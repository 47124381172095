var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Flex, Stack } from '@gameficame/ui-kit/src/components';
import { useMediaQuery } from '@mantine/hooks';
import { ChevronsLeft, ChevronsRight, LayoutDashboard, Logout, Puzzle, Receipt, Settings, ShoppingCart, UserCircle, } from 'tabler-icons-react';
import * as TablerIcons from 'tabler-icons-react';
import logoWhite from '~/assets/logo-white.svg';
import { GROUPS } from '~/layouts/constants';
import { useAuth } from '~/layouts/hooks';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import { useMe } from '~/layouts/hooks/useMe';
import { imageDomain } from '~/layouts/utils';
import { SidebarButton } from './SidebarButton';
import * as Styled from './styles';
function Sidebar(_a) {
    var rest = __rest(_a, []);
    const { data: initialData } = useInitialData();
    const { data } = useMe();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { logout } = useAuth();
    const isLargeScreen = useMediaQuery('(min-width: 768px)', true);
    const [isOpen, setIsOpen] = useState(isLargeScreen !== null && isLargeScreen !== void 0 ? isLargeScreen : true);
    useEffect(() => {
        setIsOpen(isLargeScreen !== null && isLargeScreen !== void 0 ? isLargeScreen : true);
    }, [isLargeScreen]);
    const toggleSidebar = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };
    const onClickLogout = () => __awaiter(this, void 0, void 0, function* () {
        if (logout) {
            logout();
        }
    });
    const getLetterIcon = (name) => {
        if (!name || typeof name !== 'string')
            return null;
        const initial = name.charAt(0).toUpperCase();
        const IconComponent = TablerIcons[`CircleLetter${initial}`];
        return IconComponent ? _jsx(IconComponent, { color: "white", size: "24px" }) : null;
    };
    const adminLinks = [
        {
            to: '/admin/panel',
            title: t('sidebar.option.panel'),
            icon: _jsx(LayoutDashboard, { color: "white", size: "18px" }),
        },
        {
            to: '',
            title: t('sidebar.option.mechanics'),
            icon: _jsx(Puzzle, { color: "white", size: "18px" }),
            subBtn: [
                {
                    to: '/admin/item-shop',
                    title: t('sidebar.subOption.mechanics.itemShop'),
                    soon: false,
                },
                {
                    to: '/admin/member',
                    title: t('sidebar.subOption.mechanics.inviteFriends'),
                    soon: false,
                },
                {
                    to: '/admin/vouchers',
                    title: 'Vouchers',
                    soon: false,
                },
                {
                    to: '/admin/nfes',
                    title: 'Notas Fiscais',
                    soon: false,
                },
                { to: '', title: 'Air Drop', soon: true },
                {
                    to: '',
                    title: t('sidebar.subOption.mechanics.dailyReward'),
                    soon: true,
                },
            ],
        },
        {
            to: '',
            title: 'SORTEIOS',
            icon: _jsx(Receipt, { color: "white", size: "18px" }),
            subBtn: [
                {
                    to: '/admin/new-raffle',
                    title: 'Novo Sorteio',
                    soon: false,
                },
                {
                    to: '/admin/raffle/check-winner',
                    title: 'Consultar Ganhador',
                    soon: false,
                },
                {
                    to: '/admin/raffle',
                    title: 'Ver todos os Sorteios',
                    soon: false,
                },
            ],
        },
        {
            to: '',
            title: 'CAMPANHA',
            icon: _jsx(ShoppingCart, { color: "white", size: "18px" }),
            subBtn: [
                {
                    to: '/admin/campaigns/new-campaign',
                    title: 'Nova campanha',
                    soon: false,
                },
                {
                    to: '/admin/exchange',
                    title: t('sidebar.subOption.mechanics.exchanges'),
                    soon: false,
                },
                {
                    to: '/admin/add-points',
                    title: t('sidebar.subOption.mechanics.addPoints'),
                    soon: false,
                },
                {
                    to: '/admin/campaigns',
                    title: 'Ver todas Campanhas',
                    soon: false,
                },
            ],
        },
        {
            to: '/admin/users',
            title: t('sidebar.option.users'),
            icon: _jsx(UserCircle, { color: "white", size: "18px" }),
        },
        {
            to: '',
            title: 'CONFIGURAÇÕES',
            icon: _jsx(Settings, { color: "white", size: "18px" }),
            subBtn: [
                {
                    to: '/admin/stores',
                    title: t('sidebar.subOption.mechanics.stores'),
                    soon: false,
                },
                {
                    to: '/admin/theme',
                    title: t('sidebar.subOption.mechanics.theme'),
                    soon: false,
                },
            ],
        },
    ];
    const exchangerLinks = [
        {
            to: '',
            title: 'CAMPANHA',
            icon: _jsx(ShoppingCart, { color: "white", size: "18px" }),
            subBtn: [
                {
                    to: '/admin/exchange',
                    title: t('sidebar.subOption.mechanics.exchanges'),
                    soon: false,
                },
            ],
        },
    ];
    const links = (data === null || data === void 0 ? void 0 : data.group) === GROUPS.EXCHANGER ? exchangerLinks : adminLinks;
    const logoSrc = (() => {
        var _a, _b;
        switch (isOpen) {
            case true:
                return ((_a = initialData === null || initialData === void 0 ? void 0 : initialData.theme.menu_image) === null || _a === void 0 ? void 0 : _a.url)
                    ? imageDomain + initialData.theme.menu_image.url
                    : logoWhite;
            case false:
                return ((_b = initialData === null || initialData === void 0 ? void 0 : initialData.theme.favicon_image) === null || _b === void 0 ? void 0 : _b.url)
                    ? imageDomain + initialData.theme.favicon_image.url
                    : logoWhite;
            default:
                return logoWhite;
        }
    })();
    return (_jsx(Stack, Object.assign({}, rest, { children: _jsx(Styled.SidebarBody, Object.assign({ isOpen: isOpen }, { children: _jsxs(Stack, Object.assign({ h: "100vh", pos: "sticky", top: 0 }, { children: [_jsxs(Flex, Object.assign({ p: "20px" }, { children: [_jsx(Styled.Image, { src: logoSrc, isOpen: isOpen, onClick: () => navigate('/admin/panel') }), _jsx(Styled.ToggleButton, Object.assign({ onClick: toggleSidebar }, { children: isOpen ? (_jsx(ChevronsLeft, { size: "18px", color: "white" })) : (_jsx(ChevronsRight, { size: "18px", color: "white" })) }))] })), _jsx(Styled.LinkList, { children: links.map((btn) => (_jsx(SidebarButton, { to: btn.to, title: btn.title, subBtn: btn.subBtn, icon: btn.icon, isOpen: isOpen, setIsOpen: setIsOpen }, btn.title))) }), _jsxs(Stack, Object.assign({ gap: "4px", mt: "auto", mb: "20px" }, { children: [data ? (_jsx(SidebarButton, { title: data.name, icon: getLetterIcon(data.name), isOpen: isOpen })) : null, _jsx(SidebarButton, { handleClick: onClickLogout, title: t('header.button.logout').toString(), icon: _jsx(Logout, { color: "white", size: "18px" }), isOpen: isOpen })] }))] })) })) })));
}
export default Sidebar;
